var headroom, myElement;

myElement = document.querySelector("header");

headroom = new Headroom(myElement, {
  offset: 150,
  classes: {
    notTop: "header--not-top"
  }
});

headroom.init();

document.addEventListener('DOMContentLoaded', function() {
  $('.carousel').carousel({
    indicators: true,
    numVisible: 3,
    dist: 0,
    shift: -250
  });
  $('a[data-scroll]').smoothScroll();
  if ($('.slider-recall').length > 0) {
    $('.slider-recall').each(function(i, e) {
      var $this;
      $this = $(e);
      return $this.on('init', function(event, slick, currentSlide) {
        var cur, next, prev;
        cur = $(slick.$slides[slick.currentSlide]);
        next = cur.next();
        prev = cur.prev();
        prev.addClass('slick-sprev');
        next.addClass('slick-snext');
        cur.removeClass('slick-snext').removeClass('slick-sprev');
        slick.$prev = prev;
        return slick.$next = next;
      }).on('beforeChange', function(event, slick, currentSlide, nextSlide) {
        var cur, next, prev;
        cur = $(slick.$slides[nextSlide]);
        slick.$prev.removeClass('slick-sprev');
        slick.$next.removeClass('slick-snext');
        next = cur.next();
        prev = cur.prev();
        prev.prev();
        prev.next();
        prev.addClass('slick-sprev');
        next.addClass('slick-snext');
        slick.$prev = prev;
        slick.$next = next;
        return cur.removeClass('slick-next').removeClass('slick-sprev');
      }).slick({
        infinite: true,
        dots: true,
        arrows: true,
        dotsClass: 'slider-dots',
        customPaging: function(slider, i) {
          if (slider.slideCount === 1 || slider.slideCount === slider.options.slidesToShow) {
            return false;
          }
          return "<a class='slider-dots-item'></a>";
        },
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: '<div class="slider-control slider-control-prev"></div>',
        nextArrow: '<div class="slider-control slider-control-next"></div>',
        speed: 500,
        fade: false,
        autoplay: false,
        autoplaySpeed: 5000,
        useCSS: false,
        cssEase: 'linear'
      });
    });
  }
  if ($('.slider-price').length > 0) {
    return $('.slider-price').each(function(i, e) {
      var $this;
      $this = $(e);
      return $this.slick({
        infinite: false,
        dots: false,
        arrows: false,
        swipe: false,
        centerMode: true,
        centerPadding: '24px',
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        useCSS: false,
        cssEase: 'linear',
        responsive: [
          {
            breakpoint: 980,
            settings: {
              slidesToShow: 1,
              swipe: true,
              slidesToScroll: 1
            }
          }
        ]
      });
    });
  }
});
