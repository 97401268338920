
myElement = document.querySelector("header")
headroom  = new Headroom(myElement,
  offset: 150
  classes: 
    notTop: "header--not-top"
)
headroom.init()

document.addEventListener 'DOMContentLoaded', () ->

  $('.carousel').carousel
    indicators: true
    numVisible: 3
    dist: 0
    shift: -250

  $('a[data-scroll]').smoothScroll()
      

  if $('.slider-recall').length > 0
    $('.slider-recall').each (i, e) ->
      $this = $(e)

      $this
        .on('init', (event, slick, currentSlide) ->
          cur = $(slick.$slides[slick.currentSlide])
          next = cur.next()
          prev = cur.prev()
          prev.addClass('slick-sprev')
          next.addClass('slick-snext')
          cur.removeClass('slick-snext').removeClass('slick-sprev')
          slick.$prev = prev
          slick.$next = next
        )
        .on('beforeChange', (event, slick, currentSlide, nextSlide) ->
          cur = $(slick.$slides[nextSlide])
          slick.$prev.removeClass('slick-sprev')
          slick.$next.removeClass('slick-snext')
          next = cur.next()
          prev = cur.prev()
          prev.prev()
          prev.next()
          prev.addClass('slick-sprev')
          next.addClass('slick-snext')
          slick.$prev = prev
          slick.$next = next
          cur.removeClass('slick-next').removeClass('slick-sprev')
        )
        .slick
          infinite: true
          dots: true
          arrows: true
          dotsClass: 'slider-dots'
          customPaging : (slider, i) -> 
            return false if slider.slideCount == 1 || slider.slideCount == slider.options.slidesToShow
            return "<a class='slider-dots-item'></a>"

          slidesToShow: 1
          slidesToScroll: 1
          prevArrow: '<div class="slider-control slider-control-prev"></div>'
          nextArrow: '<div class="slider-control slider-control-next"></div>'
          speed: 500
          fade: false
          autoplay: false
          autoplaySpeed: 5000
          useCSS: false
          cssEase: 'linear'


  if $('.slider-price').length > 0
    $('.slider-price').each (i, e) ->
      $this = $(e)

      $this.slick
        infinite: false
        dots: false
        arrows: false
        swipe: false
        
        centerMode: true
        centerPadding: '24px'

        slidesToShow: 1
        slidesToScroll: 1
        initialSlide: 1
        useCSS: false
        cssEase: 'linear'
        responsive: [
          {
            breakpoint: 980
            settings: {
              slidesToShow: 1
              swipe: true
              slidesToScroll: 1
            }
          }
        ] 